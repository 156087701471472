import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll,
  setEventValues,
} from '../../../../config/redux/slice/appSlice'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Select from 'react-select'
import $ from 'jquery';
import { strText } from '../../../../constants/english'
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/themes/base/all.css'; // Import jQuery UI CSS if needed
import SetTicketAlerts from './SetTicketAlert'

export default function EventFilter({
  selectedSection1,
  selectedTicketCount1,
  selectedTicketType1,
  venueObj,
  onChangeSection,
  paginationData,
  onChangeSubSection,
  getRequestFirstPage,
  onPriceChange,
  initialMinPrice,
  initialMaxPrice,
   eventDetails
}) {
  const dispatch = useDispatch()

  const [sections, setSections] = useState([])
  const [subSections, setSubSections] = useState([])
  const [selectedSection, setSelectedSection] = useState(selectedSection1 ? selectedSection1 : "")
  const [selectedSubSection, setSelectedSubSection] = useState('')
  const [defaultValue, setDefaultValue] = useState(null)


  const {
    eventManagement: { selectedMapSection },
  } = useSelector((state) => state.app)

  useEffect(() => {
    getAllSections()
  }, [venueObj])

  useEffect(() => {
    console.log('selectedMapSection', selectedMapSection);
    let singleSection = sections.filter(
      (option) => option.name.toLowerCase() === selectedMapSection?.section?.toLowerCase()
    )
  
    if (singleSection.length > 0) {
      setSelectedSection(singleSection[0].id)
    }
  
  }, [selectedMapSection])

  useEffect(() => {
    if (selectedSection !== '') {
      getAllSUbSections()
    }
  }, [selectedSection])

  const getAllSections = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      '',
      `${app_api.get_sections(venueObj?.id)}`,
      {},
      dispatch,
      '',
      '',
      ''
    )
    if (res !== undefined && res.status < 400) {
      dispatch(
        setEventValues({ propertyKey: 'sectionData', value: res.data.data })
      )
      setSections(res.data.data)
    }
  }

  const getAllSUbSections = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      '',
      `${app_api.get_subsections(selectedSection)}`,
      {},
      dispatch,
      '',
      '',
      ''
    )
    if (res !== undefined && res.status < 400) {
      setSubSections(res.data.data)
    }
  }

  const handleFilter = (numberOfTickets, ticketType, download) => {
    const numOfTickets = numberOfTickets === "All" ? "" : numberOfTickets === "5+" ? "5" : numberOfTickets;
    const requestData = {
      // ...paginationData,
      sectionId: selectedSection,
      subSections: selectedSubSection,
      numberOfTickets: numOfTickets,
      instantDownload: download
    };
    if (ticketType.length > 0) {
      requestData.ticketType = ticketType;
    }
    getRequestFirstPage(requestData);
  };

  return (
    <div
      // style={{ rowGap: '6px', columnGap: '1.3rem' }}
      className="d-flex-mob flex-row flex-xl-column flex-wrap pb-4"
    >
      <div className="form-group">
        <label className="labl-flterticker fw-bold" htmlFor="section">
          Section
        </label>
        <select
          // style={{ width: '240px' }}
          value={selectedSection}
          id="section"
          className="form-select fw-bold padd-form-select form-select-w cursor-pointer"
          onChange={(e) => {
            setSelectedSection(e.target.value)
            onChangeSection(e.target.value)
          }}
        >
          <option value={''}>All</option>
          {sections.map((section) => {
            return (
              <option key={section.id} value={section.id}>
                {section.name}
              </option>
            )
          })}
        </select>
      </div>

      {subSections.length !== 0 && selectedSection !== '' && (
        <div className="form-group">
          <label className="labl-flterticker fw-bold mb-1 mt-1" htmlFor="subsection">
            Block
          </label>
          <select
            // style={{ width: '240px' }}
            value={selectedSubSection}
            id="subsection"
            className="form-select fw-bold padd-form-select form-select-w"
            onChange={(e) => {
              setSelectedSubSection(e.target.value)
              if (selectedSection !== '') {
                onChangeSubSection(e.target.value, selectedSection)
              }
            }}
          >
            <option value={''}>All</option>
            {subSections.map((subSection) => {
              return (
                <option key={subSection.id} value={subSection.id}>
                  {subSection.name}
                </option>
              )
            })}
          </select>
        </div>
      )}

      <FilterForm
      selectedTicketCount1={selectedTicketCount1}
      selectedTicketType1={selectedTicketType1}
        defaultValue={defaultValue}
        setDefaultValue={setDefaultValue}
        handleFilter={handleFilter}
        onPriceChange={onPriceChange}
        initialMinPrice={initialMinPrice}
        initialMaxPrice={initialMaxPrice}
        venueObj={venueObj}
        eventDetails={eventDetails}
      />
    </div>
  )
}

const FilterForm = ({selectedTicketCount1,selectedTicketType1, handleFilter, defaultValue, setDefaultValue, onPriceChange, initialMinPrice, initialMaxPrice, venueObj, eventDetails }) => {
  const numberOfElements = 30

  // Initialize an array to store the elements
  const options = [
    {
      value: '',
      label: 'All',
    },
  ]

  // Use a for loop to generate the elements
  for (let i = 1; i <= numberOfElements; i++) {
    options.push({
      value: i,
      label: `${i} ${i > 1 ? 'tickets' : 'ticket'}`,
    })
  }

  const fontSize = '0.9rem'
  const theStyle = (error) => {
    const style = {
      singleValue: (defaultStyles) => {
        return {
          ...defaultStyles,
          fontSize,
          fontWeight: 'bold',
        }
      },
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          fontSize,
        }
      },
      option: (defaultStyles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...defaultStyles,
          fontSize: '0.8rem',
          cursor: 'pointer',
          minHeight: '1.2em',
          padding: '0px 2px 1px',
          whiteSpace: 'nowrap',
          //  backgroundColor: isSelected ? '#00BAFF' : null,
          //  color: isSelected ? '#6B7280' : null,
          '&:hover': {
            // color: '#6B7280',
            backgroundColor: '#1e90ff',
            color: 'white',
          },
        }
      },
      dropdownIndicator: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: '#4A5055',
          fontWeight: '300',
        }
      },
      control: (defaultStyles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...defaultStyles,
          borderColor: isFocused ? '#A7AEC4' : defaultStyles.borderColor,
          outline: isFocused
            ? '4.4px solid rgb(167, 174, 196, 0.8)'
            : defaultStyles.outline,
          '&:hover': {
            borderColor: isFocused ? '#A7AEC4' : defaultStyles.borderColor,
          },
        }
      },
      input: (defaultStyles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...defaultStyles,
          fontWeight: 'bold',
        }
      },
    }

    return style
  }

  const indicatorSeparatorStyle = {
    display: 'none',
    width: 0,
  }

  const IndicatorSeparator = ({ innerProps }) => {
    return <span style={indicatorSeparatorStyle} {...innerProps} />
  }

  const [selectedTicketCount, setSelectedTicketCount] = useState(selectedTicketCount1 ? selectedTicketCount1 :'All');
  const [selectedTicketType, setSelectedTicketType] = useState(
    selectedTicketType1 ? (Array.isArray(selectedTicketType1) ? selectedTicketType1 : [selectedTicketType1]) : []
  );
  const [isInstantDownload, setIsInstantDownload] = useState(false);

  const [minPrice, setMinPrice] = useState(initialMinPrice || '');
  const [maxPrice, setMaxPrice] = useState(initialMaxPrice || '');
  const priceGap = 1;
  const maxRange = initialMaxPrice;
  const sliderRef = useRef(null);
  const [showAlertPopup, setShowAlertPopup] = useState(false)

  const sliderInitialized = useRef(false);
  useEffect(() => {
    setMinPrice(initialMinPrice);
    setMaxPrice(initialMaxPrice);
  }, [initialMinPrice, initialMaxPrice]);

  useEffect(() => {
    // Convert state values to numbers, but avoid defaulting to 0
    const minPriceValue = minPrice ? Number(minPrice) : null;
    const maxPriceValue = maxPrice ? Number(maxPrice) : null;
    const initialMinPriceValue = initialMinPrice ? Number(initialMinPrice) : null;
    const initialMaxPriceValue = initialMaxPrice ? Number(initialMaxPrice) : null;

    // Initialize the slider
    $(sliderRef.current).slider({
      range: true,
      min: initialMinPriceValue || 0, // Default to 0 if no valid initial value
      max: initialMaxPriceValue || 0,
      values: [minPriceValue || 0, maxPriceValue || 0],
      create: function () {
        const $handles = $(this).find('.ui-slider-handle');

        // Conditionally add tooltips based on initialMinPrice and initialMaxPrice
        if (initialMinPriceValue !== null && initialMaxPriceValue !== null) {
          $handles.first().append(`<div class="tooltipabc">${minPriceValue}</div>`);
          $handles.last().append(`<div class="tooltipabc">${maxPriceValue}</div>`);
        }
      },
      slide: (event, ui) => {
        setMinPrice(ui.values[0]);
        setMaxPrice(ui.values[1]);

        // Update the tooltip's content only if the tooltip is present
        const $currentHandle = $(ui.handle);
        if ($currentHandle.find('.tooltipabc').length > 0) {
          $currentHandle.find('.tooltipabc').text(ui.value);
        }
      },
      stop: (event, ui) => {
        onPriceChange(ui.values[0], ui.values[1]);
      }
    });

    // Update tooltips after the slider is initialized, if tooltips are present
    const $handles = $(sliderRef.current).find('.ui-slider-handle');
    if (initialMinPriceValue !== null && initialMaxPriceValue !== null) {
      $handles.first().find('.tooltipabc').text(`${strText.currency_symbol}${minPriceValue}`);
      $handles.last().find('.tooltipabc').text(`${strText.currency_symbol}${maxPriceValue}`);
    } else {
      if ($(sliderRef.current).hasClass("ui-slider")) {
        $(sliderRef.current).slider('destroy');
        $handles.first().find('.tooltipabc').remove(); // Remove tooltip if values are null
        $handles.last().find('.tooltipabc').remove();
      }
      // Remove tooltip if values are null
    }

    // Cleanup function
    return () => {
      // if ($(sliderRef.current).hasClass("ui-slider")) {
      //   $(sliderRef.current).slider('destroy');
      // }
    };
  }, [minPrice, maxPrice, initialMinPrice, initialMaxPrice, onPriceChange]);



  onPriceChange(minPrice, maxPrice);
  useEffect(() => {
  }, [minPrice, maxPrice]);
  const handleMinInputChange = (e) => {
    const value = e.target.value;

    if (value === '') {
      setMinPrice(''); // Keep the field empty
      $(sliderRef.current).slider('values', 0, 0); // Reset the slider to 0 or maintain its position
      onPriceChange('', maxPrice); // Call onPriceChange with the current maxPrice
      return;
    }

    const intValue = parseInt(value);
    if (!isNaN(intValue) && intValue >= 0 && intValue <= maxRange) {
      setMinPrice(intValue);
      $(sliderRef.current).slider('values', 0, intValue);
      onPriceChange(intValue, maxPrice); // Call onPriceChange with the updated minPrice
    }
  };



  const handleMaxInputChange = (e) => {
    const value = e.target.value;

    if (value === '') {
      setMaxPrice(''); // Keep the field empty
      $(sliderRef.current).slider('values', 1, maxRange); // Reset the slider to maxRange or maintain its position
      onPriceChange(minPrice, ''); // Call onPriceChange with the current minPrice
      return;
    }

    const intValue = parseInt(value);
    if (!isNaN(intValue) && intValue >= 0 && intValue <= maxRange) {
      setMaxPrice(intValue);
      $(sliderRef.current).slider('values', 1, intValue);
      onPriceChange(minPrice, intValue); // Call onPriceChange with the updated maxPrice
    }
  };


  const handleMinRangeChange = (e) => {
    const value = parseInt(e.target.value);
    if ((maxPrice - value >= priceGap)) {
      setMinPrice(value);
    } else {
      setMinPrice(maxPrice - priceGap);
    }
  };

  const handleMaxRangeChange = (e) => {
    const value = parseInt(e.target.value);
    if ((value - minPrice >= priceGap)) {
      setMaxPrice(value);
    } else {
      setMaxPrice(minPrice + priceGap);
    }
  };

  const handleTicketCountClick = (val) => {
    if (selectedTicketCount === val) {
      setSelectedTicketCount('All');
      handleFilter('All', selectedTicketType, isInstantDownload);
    } else {
      setSelectedTicketCount(val);
      handleFilter(val, selectedTicketType, isInstantDownload);
    }
  };


  const handleTicketTypeClick = (type) => {
    let updatedTicketTypes;

    if (selectedTicketType.includes(type)) {
      updatedTicketTypes = selectedTicketType.filter(t => t !== type);
    } else {
      updatedTicketTypes = [...selectedTicketType, type];
    }

    setSelectedTicketType(updatedTicketTypes);
    handleFilter(selectedTicketCount, updatedTicketTypes, isInstantDownload);
  };


  const handleInstantDownloadChange = (event) => {
    const isChecked = event.target.checked;
    setIsInstantDownload(isChecked);
    if (isChecked) {
      // Set the ticket type to only "ELECTRONIC"
      setSelectedTicketType(["ELECTRONIC"]);
      handleFilter(selectedTicketCount, ["ELECTRONIC"], true);
    } else {
      // Clear the selection if Instant Download is unchecked
      setSelectedTicketType(["ELECTRONIC"]);
      handleFilter(selectedTicketCount, ["ELECTRONIC"], false);
    }
  };
  const handleBeforeChange = (value) => {
    console.log('Interaction started', value);
  };

  const handleSetAlert= () => {
    setShowAlertPopup(true);
  }

  const handleRangeChange = (value) => {
    setMinPrice(value[0]);
    setMaxPrice(value[1]);
    handleAfterChange(value)
  };

  const handleAfterChange = (value) => {
    console.log('Interaction finished', value);
    onPriceChange(value[0], value[1]);
  }
  const ticketValues = [1, 2, 3, 4, "5+"];
  const ticketTypes = ["MOBILE", "ELECTRONIC", "PHYSICAL"];
  return (
    <div>
      <div className='d-flex-mob'>
        <div className="ticket-filter-outer" >
          <label className="fw-bold mt-2 labl-flterticker">Number of tickets</label>
          <div className="">
            {ticketValues.map((val, index) => (
              <div
                key={index}
                className={`ticket-filtersec ${selectedTicketCount === val ? 'ticket-filtersec-select' : ''}`}
                onClick={() => handleTicketCountClick(val)}
              >
                {val}
              </div>
            ))}
          </div>
        </div>

        <div className="type-ticket-wrpr">
          <label className="fw-bold mt-2 labl-flterticker">Type of Tickets</label>
          <div className="type-ticket-sec">
          {ticketTypes.map((type, index) => (
  <div
    key={index}
    className={`type-ticket-secblock ${selectedTicketType.includes(type) ? 'type-ticket-select' : ''}`}
    onClick={() => handleTicketTypeClick(type)}
  >
    <span>{type === 'ELECTRONIC' ? 'E-Ticket' : type}</span>
  </div>
))}
          </div>
        </div>
      </div>

      <div className="instant-download-sec">
        <div class="form-switch">
          <input class="form-check-input mt-1 cursor-pointer" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={isInstantDownload}
            onChange={handleInstantDownloadChange} />
          <label class="form-check-label cursor-pointer" for="flexSwitchCheckDefault" style={{ marginLeft: "10px" }}><span className='card-body-title fw-bold tx-12 instnt-dwnlodtext' >Instant Download</span></label>
        </div>

      </div>

      <div className="ticket-price-wrapper pb-2">
        <label className="fw-bold mt-0 labl-flterticker">Price Per Ticket</label>
        <div className="wrapper">

          {/* <div className="price-input">
            <div className="field">
              <span>Min</span>
              <input
                type="number"
                className="input-min"
                value={minPrice}
                onChange={handleMinInputChange}
              />
            </div>
            <div className="separator">-</div>
            <div className="field">
              <span>Max</span>
              <input
                type="number"
                className="input-max"
                value={maxPrice}
                onChange={handleMaxInputChange}
              />
            </div>
          </div> */}

          <div ref={sliderRef} id="slider"></div>

        </div>
      </div>

      <div class="pt-4 d-flex align-items-center justify-content-center">
        <button onClick={handleSetAlert} className='btn-header-sec bg-btn-header'>
        Set an Alert
      </button>

      <SetTicketAlerts setShowAlertPopup={setShowAlertPopup} showAlertPopup={showAlertPopup} venueObj={venueObj}
      eventDetails={eventDetails} />

      </div>
    </div>
  )
}
