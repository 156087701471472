import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, setCustomerLoading, onGetProfileSales, setCustomerErrorMessage } from '../../../../config/redux/slice/customerSlice'
import moment from 'moment'
import Pagination from "react-js-pagination";

import { app_api } from '../../../../utils/endpoints/app_api'
import { get_delete_request } from '../../../../helpers/request'

import { useNavigate } from 'react-router'

export default function ClosedAlertsList(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { token } = useSelector((state) => state.authData)
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);
    const [nestedExpandedRows, setNestedExpandedRows] = useState({});
    const [activePage, setActivePage] = useState(1);
    const [recordsTotal, setRecordsTotal] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
   
    const [listingData, setListingData] = useState()
    const [nestedExpandedRowsList, setNestedExpandedRowsList] = useState({});


    useEffect(() => {
        getList();
    }, [])

    const getList = async (page) => {
        dispatch(clearAll({}))
        const res = await get_delete_request(
          'get',
          token,
          `${app_api.list_closed_alerts}?page=1&pageSize=10&draw=2&q=&_=1720424965852`,
          {},
          dispatch,
          setCustomerLoading,
          onGetProfileSales,
          setCustomerErrorMessage,
          false
        )
        if (res !== undefined && res.status < 400) {
          console.log(res?.data);
          setListingData(res?.data?.data || [])
          setLoading(false);
          setRecordsTotal(res?.data?.recordsTotal)

        //   setListingCount(res?.data?.recordsTotal)
        }
      }

    const { alertManagement: { showAlertModal: { show, type } } } = useSelector((state) => state.customer)

    const toggleRow = (index, event) => {
        event.preventDefault();
        event.stopPropagation();
        setExpandedRows(prevExpandedRows => ({
            ...Object.fromEntries(Object.keys(prevExpandedRows).map(key => [key, false])), // Close all other rows
            [index]: !prevExpandedRows[index]
        }));
        setNestedExpandedRows({});
        setNestedExpandedRowsList({});
    };


    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        localStorage.setItem("searchTerm", searchTerm);
    };
    const handleSearchChange = async (event) => {
        setLoading(true);
        setExpandedRows({})
        setNestedExpandedRows({});
        setNestedExpandedRowsList({});
        dispatch(clearAll({}));
        const pageSize = 10; // Adjust this value as per your requirements
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.list_closed_alerts}?page=1&pageSize=${pageSize}&draw=2&q=${searchTerm}&_=${Date.now()}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );
        console.log("🚀 ~ handleSearchChange ~ res:", res);
        if (res !== undefined && res.status < 400) {
            setListingData(res.data.data)
            setRecordsTotal(res.data.recordsFiltered)
            setLoading(false);
        } else {
            setListingData([])
            setRecordsTotal("")
            setLoading(false);
        }

    }
    const handlePageChange = async (pageValue) => {
        setLoading(true);
        setExpandedRows({})
        setNestedExpandedRows({});
        setNestedExpandedRowsList({});
        setActivePage(pageValue)
        dispatch(clearAll({}));
        const pageSize = 10; // Adjust this value as per your requirements
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.list_closed_alerts}?search=&page=${pageValue}&pageSize=${pageSize}&draw=2&q=${searchTerm}&_=${Date.now()}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );
        console.log("🚀 ~ handleSearchChange ~ res:", res);
        if (res !== undefined && res.status < 400) {
            setListingData(res.data.data)
            setRecordsTotal(res.data.recordsFiltered)
            setLoading(false);
        }
        else {
            setListingData([])
            setRecordsTotal("")
            setLoading(false);
        }

    }

    const toggleNestedRow = (mainIndex, nestedIndex, event) => {
        event.preventDefault();
        event.stopPropagation();
        setNestedExpandedRows(prevState => ({
            ...prevState,
            [mainIndex]: {
                ...prevState[mainIndex],
                [nestedIndex]: !prevState[mainIndex]?.[nestedIndex]
            }
        }));
    };

    return (
        <div >
            <div className="payable-tblouter order-tbl">
                <div className="form-group search-input-top">
                    <input autoComplete='off' type="text" className="form-control" id="searchValue" placeholder="Search..." onChange={handleInputChange} />
                    <button className="btn btn-primary me-3 ml-2" style={{ marginLeft: "10px" }} onClick={() => handleSearchChange()}>Search</button>
                </div>

                <div className="table-responsive web-show-tbl">
                    <table className="table table-striped mt-3">
                        <thead>
                            <tr>
                                <th scope="col" className="no-wrap">Event</th>
                                <th scope="col" className="no-wrap">Event Date</th>
                                <th scope="col" className="no-wrap">No of Alerts</th>
                                <th scope="col" className="no-wrap">Expand</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="6" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                                </tr>
                            ) : listingData.length > 0 ? (
                                listingData.map((item, index) => (
                                    <>
                                        <tr key={index} className={expandedRows[index] ? 'fontWeight' : ""}>
                                            <td>
                                                {item?.eventName}

                                            </td>
                                            <td>{moment(item?.eventDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</td>
                                            <td>{item?.totalNumberOfAlerts || 0}</td>
                                            <td> <a title='Details Payout' className="btn btn-sm bg-primary text-white btn-icon view-details btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                                {expandedRows[index] ? (
                                                    <i className="fa fa-angle-up fa-sizeset"></i>
                                                ) : (
                                                    <i className="fa fa-angle-down fa-sizeset"></i>
                                                )} </a></td>
                                        </tr>


                                        {(expandedRows[index] && item.buyerDetails.length > 0) && (
                                            <tr>
                                                <td colSpan="8">
                                                    <table className="table table-striped tbl-inner-width">
                                                        <thead>
                                                            <tr>
                                                                <th className="no-wrap">Buyer Name</th>
                                                                <th className="no-wrap">Buyer Email</th>
                                                                <th className="no-wrap">Buyer Phone</th>

                                                                <th className="no-wrap">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        {item.buyerDetails.map((items, orderIndex) => (
                                                            <tbody>
                                                                <tr key={orderIndex}>
                                                                    <td>{items?.buyerName}</td>
                                                                    <td>{items?.buyerEmail}</td>
                                                                    <td>{items?.buyerPhone}</td>
                                                                    <td>
                                                                        <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleNestedRow(index, orderIndex, event)}>
                                                                            {nestedExpandedRows[index]?.[orderIndex] ? (
                                                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                                                            ) : (
                                                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                                                            )}
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                                {nestedExpandedRows[index]?.[orderIndex] && (
                                                                    <tr>
                                                                        <td colSpan="7">
                                                                            <table className="table table-bordered tbl-subinner-width">
                                                                                <thead>
                                                                                    <tr>
                                                                                    <th className="no-wrap">Seller Details</th>

                                                                                        <th className="no-wrap">No of Tickets</th>
                                                                                        <th className="no-wrap">Price</th>
                                                                                        <th className="no-wrap">Expiry Date</th>
                                                                                        <th className="no-wrap">Section/Block</th>
                                                                                        <th className="no-wrap">Ticket Type</th>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {items?.alerts?.map((alerts, alertIndex) => (
                                                                                        <tr key={orderIndex}>
                                                                                            <td><b>Name: </b>{alerts?.sellerName} <br />
                                                                                            <b>Email: </b>{alerts?.sellerEmail} <br />
                                                                                            <b>Phone: </b>{alerts?.sellerPhone}<br />
                                                                                            </td>
                                                                                            <td>{alerts?.numberOfTickets}</td>
                                                                                            <td>{alerts?.price ? alerts?.price : 'N/A'}</td>
                                                                                            <td>{moment(alerts?.expiryDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</td>
                                                                                            <td>{alerts?.sectionName ? alerts.sectionName : 'N/A'}<br />
                                                                                                {alerts?.subSectionName ? alerts.subSectionName : 'N/A'}
                                                                                            </td>
                                                                                            <td>{alerts?.ticketType}</td>
                                                                                            
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>))}
                                                    </table>
                                                </td>
                                            </tr>
                                        )}

                                    </>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="text-center py-4">No Data Found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="mob-table-section">
                    {loading ? (
                        <div className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></div>
                    ) : listingData.length > 0 ? (
                        listingData.map((item, index) => (
                            <div className={`mob-table-sec-outer ${expandedRows[index] ? 'fontWeight' : ''}`} key={index}>
                                <div className="position-relative">
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event</h3>
                                        <div className="mob-tbl-value" title={item?.eventName}>{item?.eventName}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event Date</h3>
                                        <div className="mob-tbl-value">{moment(item?.eventDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">No of Alerts</h3>
                                        <div className="mob-tbl-value">{item?.totalNumberOfAlerts || 0}</div>
                                    </div>
                                    <div className="mob-table-sec-expand">
                                        <a title="View Items" className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                            {expandedRows[index] ? (
                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                            ) : (
                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                            )}
                                        </a>
                                    </div>
                                </div>

                                {expandedRows[index] && item.buyerDetails.length > 0 && (
                                    <div className="mob-tbl-inner-sec">
                                        {item.buyerDetails.map((buyer, buyerIndex) => (
                                            <div key={buyerIndex} className="mob-table-sec">
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Buyer Name</h3>
                                                    <div className="mob-tbl-value">{buyer?.buyerName}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Buyer Email</h3>
                                                    <div className="mob-tbl-value">{buyer?.buyerEmail}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Buyer Phone</h3>
                                                    <div className="mob-tbl-value">{buyer?.buyerPhone}</div>
                                                </div>
                                                <div className="mob-table-sec-expand">
                                                    <a title="View Alerts" className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleNestedRow(index, buyerIndex, event)}>
                                                        {nestedExpandedRows[index]?.[buyerIndex] ? (
                                                            <i className="fa fa-angle-up fa-sizeset"></i>
                                                        ) : (
                                                            <i className="fa fa-angle-down fa-sizeset"></i>
                                                        )}
                                                    </a>
                                                </div>


                                                   {nestedExpandedRows[index] && (
                                                    <>
                                                        {buyer.alerts.map((alert, itemIndex) => {
                                                            return (

                                                                <div className="mob-tb-subinner" key={itemIndex}>
 <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Seller Details</h3>
                                                                        <div className={`mob-tbl-value`}>
                                                                        <b>Name: </b>{alert?.sellerName} <br />
                                                                                            <b>Email: </b>{alert?.sellerEmail} <br />
                                                                                            <b>Phone: </b>{alert?.sellerPhone}<br />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">No of Tickets</h3>
                                                                        <div className={`mob-tbl-value`}>{alert?.numberOfTickets || 0}</div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Price</h3>
                                                                        <div className="mob-tbl-value">
                                                                            {alert?.price || 'N/A'}
                                                                           </div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Expiry Date</h3>
                                                                        <div className="mob-tbl-value">{moment(alert?.expiryDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Section/Block</h3>
                                                                        <div className="mob-tbl-value">{alert?.sectionName ? alert.sectionName : 'N/A'}
                                                            <br />
                                                            {alert?.subSectionName ? alert.subSectionName : 'N/A'}</div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Ticket Type</h3>
                                                                        <div className="mob-tbl-value">{alert?.ticketType}</div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="text-center py-4">No Data Found</div>
                    )}
                </div>




                {recordsTotal && recordsTotal > 10 ?
                    <div className='pagination-wrapper'>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={recordsTotal}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                    </div>
                    : ""}


            </div>
        </div >
    );
}
